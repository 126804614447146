import ShopModal from "./ShopModal";
import {useState} from "react";
import {Shop} from "../../models/shop";

type ShopEntryOptions = {
  shop: Shop
}

export default function ShopEntry(opt: ShopEntryOptions) {

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
      <>
        <div className="w-full h-full" onClick={() => setIsModalOpen(true)}>
          <div
              className="flex items-center px-4 justify-center py-4 h-full lg:px-9 lg:py-9 cursor-pointer hover:bg-gray-100 bg-white shadow" {...opt}>
            <img
                className={`h-full rounded-lg p-4 xl:p-8 object-contain ${opt.shop.invertImage ? 'invert' : ''}`}
                src={opt.shop.image}/>
          </div>
        </div>
        <ShopModal shop={opt.shop}
                   isOpen={isModalOpen} close={() => setIsModalOpen(false)}/>
      </>
  )
}
